import React from 'react'
import admin from '../../Assets/admin.png'

const Imageheader = () => {
  return (
    <div className='md:mt-20 mt-4 items-center justify-center flex text-center md:hidden'>
        <img
        className=''
        src={admin}
        alt=''
        />
    </div>
  )
}

export default Imageheader