import React from 'react'

const Construction = () => {
  return (
    <div className='m-10'>
        <h2>
            Dear User this Page is Under Construction,
        </h2>
        <p className='text-sm font-gilroysemibold'>Please Check back in some other time. We are so sorry for any inconvinience this has caused.</p>
    </div>
  )
}

export default Construction